import React from 'react';
import { MsalProvider } from '@azure/msal-react';

import AppRouter from './AppRouter';

function App({ pca }) {
  return (
    <MsalProvider instance={pca}>
      <AppRouter />
    </MsalProvider>
  );
}

export default App;
