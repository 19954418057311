import React from "react";
import "./index.css";
import logo from "../../logo.svg";

const Footer = () => {
  return (
    <>
      <div className=" w-100 mt-7">
        <footer id="lds-footer" className="lds-footer">
          <div className="footWrap">
            <div className="row">
              <div className="col-12 col-md-9 lds-footer-main-links">
                <div className="row">
                  <div className="col-12 col-md-auto">
                    <a
                      href="https://www.lilly.co.jp/operating-responsibly/diversity-inclusion"
                      target="_self"
                      className="lds-link"
                    >
                      ダイバーシティ
                    </a>
                  </div>
                  <div className="col-12 col-md-auto">
                    <a
                      href="https://www.lilly.co.jp/contact-us"
                      target="_self"
                      className="lds-link"
                    >
                      お問い合わせ
                    </a>
                  </div>
                  <div className="col-12 col-md-auto">
                    <a
                      href="https://www.lilly.co.jp/suppliers"
                      target="_self"
                      className="lds-link"
                    >
                      取引先向け情報
                    </a>
                  </div>
                  <div className="col-12 col-md-auto">
                    <a
                      href="https://www.lilly.co.jp/accessibility"
                      target="_self"
                      className="lds-link"
                    >
                      アクセシビリティに関して
                    </a>
                  </div>
                  <div className="col-12 col-md-auto d-md-none">
                    <div>
                      <a
                        href="https://www.lilly.co.jp/terms-of-use"
                        target="_self"
                        className="lds-link lds-footer-main-links-global"
                      >
                        利用規約
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.lilly.co.jp/privacy"
                        target="_self"
                        className="lds-link lds-footer-main-links-global"
                      >
                        個人情報の保護について
                      </a>
                    </div>
                    <div>
                      <a
                        href="https://www.lilly.co.jp/sitemap"
                        target="_self"
                        className="lds-link lds-footer-main-links-global"
                      >
                        サイトマップ
                      </a>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-md-3 lds-footer-support">
                <div> &nbsp; </div>
              </div>
            </div>
            <div className="lds-footer-meta text-left">
              <div>
                <p>Copyright © 2025 Eli Lilly Japan K.K. All rights reserved. <br/>PP-MG-JP-2442<br/>当サイトは、18歳未満の方向けに制作されたサイトではございません。</p>
              </div>
            </div>
            <div className="row">
              <div className="col-12 col-md-auto order-md-2 ml-auto">
                <div className="lds-footer-social-icons"></div>
                <div className="lds-footer-global-links d-none d-md-block">
                  <a
                    href="https://www.lilly.co.jp/terms-of-use"
                    target="_self"
                    className="lds-link"
                  >
                    利用規約
                  </a>
                  <a
                    href="https://www.lilly.co.jp/privacy"
                    target="_self"
                    className="lds-link"
                  >
                    個人情報の保護について
                  </a>
                  <a
                    href="https://www.lilly.co.jp/sitemap"
                    target="_self"
                    className="lds-link"
                  >
                    サイトマップ
                  </a>
                </div>
              </div>
              <div className="col-12 col-md-auto order-md-1 lds-footer-logo">
                <div>
                  <a
                    href="https://www.lilly.co.jp/"
                    className="lds-link lds-logo-lilly logo-md logo-red"
                    alt="lilly logo"
                  >
                    <img src={logo} width="100" height="50"></img>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </footer>
      </div>
    </>
  );
};

export default Footer;
