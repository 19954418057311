import React from 'react';
import ReactDOM from 'react-dom';
import { EventType } from '@azure/msal-browser';
import './index.css';

import App from './App';
import * as serviceWorker from './serviceWorker';
import { msalInstance, loginRequest } from './authConfig';

class RootComponent extends React.Component {
  componentDidMount() {
    const path = window.location.pathname;
    if (
      !window.sessionStorage.getItem('loggedinUserName') &&
      (path === '/jp/admin' || path === '/jp/new' || path.startsWith('/jp/edit'))
    ) {
      msalInstance.initialize().then(async () => {
        // Default to using the first account if no account is active on page load
        if (!msalInstance.getActiveAccount() && msalInstance.getAllAccounts().length > 0) {
          // Account selection logic is app dependent. Adjust as needed for different use cases.
          msalInstance.setActiveAccount(msalInstance.getAllAccounts()[0]);
        } else {
          msalInstance.loginRedirect(loginRequest);
        }
        // Event for handle callback
        msalInstance.addEventCallback((event) => {
          if (event.eventType === EventType.LOGIN_SUCCESS && event.payload.account) {
            const account = event.payload.account;
            msalInstance.setActiveAccount(account);
          }
        });
      });
    }
  }

  render() {
    return (
      <div className="App">
        <App pca={msalInstance} />
      </div>
    );
  }
}

ReactDOM.render(<RootComponent />, document.getElementById('root'));

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
