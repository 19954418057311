import routes from 'routes';
import Preview from 'pages/Preview';
import Loader from 'components/Loader';
import Home from 'components/Home/index';
import DisplayPDF from 'pages/DisplayPDF';
import PageNotFound from 'pages/PageNotFound';
import AuthCallBack from 'components/AuthCallBack';
import React, { Suspense, Component } from 'react';
import ConfirmationModal from 'components/ConfirmationModal';
import { Route, Switch, Redirect, BrowserRouter as Router } from 'react-router-dom';

import configData from './config.json';
import { msalInstance, loginRequest } from './authConfig';

class AppRouter extends Component {
  constructor(props) {
    super(props);
    this.state = {
      showModal: false,
      isAuthenticated: false,
      hasFetchedAuthenticationState: false
    };
  }

  async componentDidMount() {
    const path = window.location.pathname;
    if (path === '/jp/admin' || path === '/jp/new' || path.startsWith('/jp/edit')) {
      try {
        if (window.sessionStorage.getItem('loggedinUserName')) {
          this.setState({
            showModal: true,
            isAuthenticated: true,
            hasFetchedAuthenticationState: true
          });
        } else {
          await msalInstance.initialize().then(async () => {
            // Handle the redirect flows and set accessToken and idToken
            await msalInstance.handleRedirectPromise().then(async (response) => {
              if (!response) {
                const accounts = msalInstance.getAllAccounts();
                if (accounts.length === 0) {
                  msalInstance.loginRedirect(loginRequest);
                }
              } else {
                const scopes = ['api://39b64b04-e385-4232-a4e2-604367e2d849/.default'];
                const authResult = await msalInstance.acquireTokenSilent({
                  scopes
                });
                sessionStorage.setItem('apiToken', authResult.accessToken);
                sessionStorage.setItem('loggedinUserName', response?.account?.idTokenClaims?.name);
                const isMember = await this.checkMembership(
                  response.accessToken,
                  configData.GROUP_ID
                );
                console.log('isMember', isMember);
                this.setState({ hasFetchedAuthenticationState: true, showModal: true });
                if (isMember) {
                  this.setState({ isAuthenticated: true });
                } else {
                  this.setState({ isAuthenticated: false });
                }
              }
            });
          });
        }
      } catch (error) {
        console.log('error', error);
        this.setState({
          isAuthenticated: false,
          hasFetchedAuthenticationState: false
        });
        throw new Error('Authentication status not available');
      }
    } else {
      this.setState({ hasFetchedAuthenticationState: true, showModal: true });
    }
  }

  async checkMembership(accessToken, groupId) {
    try {
      const apiResponse = await fetch('https://graph.microsoft.com/v1.0/me/checkMemberGroups', {
        method: 'POST',
        headers: {
          Authorization: `Bearer ${accessToken}`,
          'Content-Type': 'application/json'
        },
        body: JSON.stringify({
          groupIds: [groupId]
        })
      });
      const response = await apiResponse.json();
      return response && response.value && response.value.length > 0;
    } catch (error) {
      console.log('Error:', error);
      return false;
    }
  }

  handleYesClick = () => {
    this.setState({ showModal: false });
  };

  render() {
    const { isAuthenticated, hasFetchedAuthenticationState, showModal } = this.state;
    console.log('state', this.state);
    if (hasFetchedAuthenticationState) {
      return (
        <Router>
          <Suspense fallback={<div>Loading...</div>}>
            <Switch>
              {routes.map((route) => (
                <Route
                  key={route.path}
                  path={route.path}
                  component={() => (isAuthenticated ? <route.component /> : <PageNotFound />)}
                  exact={true}
                />
              ))}
              <Route path="/jp" exact={true}>
                <Home />
              </Route>
              <Route path="/jp/news_list.html" exact={true}>
                <Home />
              </Route>
              <Route path="/jp/previewPDF/:param1/:param2" exact={true}>
                <DisplayPDF />
              </Route>
              <Route path="/jp/preview" exact={true}>
                {Preview}
              </Route>
              <Route
                path="/auth/callback"
                render={(props) => <AuthCallBack history={props.history} />}
                exact={true}
              />
              <Route path="/jp/Unauthorized" exact={true}>
                <PageNotFound />
              </Route>
              <Redirect from={'/'} to={'/jp'} exact={true} />
              <Redirect from={'/new'} to={'/jp/new'} exact={true} />
              <Redirect from={'/admin'} to={'/jp/admin'} exact={true} />
              <Redirect from={'/error'} to={'/jp/error'} exact={true} />
              <Redirect from={'/news_list.html'} to={'/jp/news_list.html'} exact={true} />
              <Redirect
                from={'/previewPDF/:param1/:param2'}
                to={'/jp/previewPDF/:param1/:param2'}
                exact={true}
              />
              <Route path="*" exact={true}>
                <PageNotFound />
              </Route>
            </Switch>
          </Suspense>
          {showModal && (
            <ConfirmationModal
              show={showModal}
              data-keyboard={false}
              handleYesClick={this.handleYesClick}
              handleClose={() => this.setState({ showModal: false })}
            />
          )}
        </Router>
      );
    }

    return (
      <Loader>
        <div>Hello World!</div>
      </Loader>
    );
  }
}

export default AppRouter;
