import React from 'react';
import PropTypes from 'prop-types';

import Loader from './Loader';

class AuthCallBack extends React.Component {
  constructor(props) {
    super(props);
  }

  render() {
    return <Loader />;
  }
}

AuthCallBack.propTypes = {
  history: PropTypes.shape({
    push: PropTypes.func.isRequired
  }).isRequired
};

export default AuthCallBack;
